<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="Hệ đào tạo:"
                  label-for="trainingSystemSelected"
                >
                  <v-select
                    v-model="trainingSystemSelected"
                    :options="trainingSystemsOptions"
                    :reduce="option => option.value"
                    @input="onChangeTrainingSystem"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="Khóa học:"
                  label-for="courseSelected"
                >
                  <v-select
                    v-model="courseSelected"
                    :options="courseOptions"
                    :reduce="option => option.value"
                    @input="onChangeCourse"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="Ngành học:"
                  label-for="majorSelected"
                >
                  <v-select
                    v-model="majorSelected"
                    :options="majorOptions"
                    :reduce="option => option.value"
                    @input="onChangeMajor"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="Lớp học:"
                  label-for="classSelect"
                >
                  <v-select
                    v-model="classSelect"
                    :options="classOptions"
                    :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="Học kỳ:"
                  label-for="semesterSelected"
                >
                  <v-select
                    v-model="semesterSelected"
                    :options="semesterOptions"
                    :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                    v-show="classSelect > 0 && semesterSelected > 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-right: 5px"
                    @click="onSelect"
                  >
                    <span class="text-nowrap text-right">Danh sách</span>
                  </b-button>
                  <b-button
                      v-show="this.itemsTable.length > 0"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      style="margin-right: 5px"
                      @click="exportExcel"
                  >
                    <span class="text-nowrap text-right">Xuất excel</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div>
                  <b-table
                    ref="table"
                    :small="true"
                    :hover="true"
                    :items="itemsTable"
                    :fields="fieldsTable"
                  >
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                  </b-table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <!--    <vue-html2pdf-->
    <!--      ref="html2Pdf"-->
    <!--      :show-layout="controlValue.showLayout"-->
    <!--      :float-layout="controlValue.floatLayout"-->
    <!--      :enable-download="controlValue.enableDownload"-->
    <!--      :preview-modal="controlValue.previewModal"-->
    <!--      :filename="controlValue.filename"-->
    <!--      :paginate-elements-by-height="controlValue.paginateElementsByHeight"-->
    <!--      :pdf-quality="controlValue.pdfQuality"-->
    <!--      :pdf-format="controlValue.pdfFormat"-->
    <!--      :pdf-orientation="controlValue.pdfOrientation"-->
    <!--      :pdf-content-width="controlValue.pdfContentWidth"-->
    <!--      :manual-pagination="controlValue.manualPagination"-->
    <!--      :html-to-pdf-options="htmlToPdfOptions"-->
    <!--      @hasDownloaded="hasDownloaded($event)"-->
    <!--    >-->
    <!--      <pdf-content-->
    <!--        slot="pdf-content"-->
    <!--        :content-pdf="contentPdf"-->
    <!--        :list-student-examination-room="this.avgStudentClasss"-->
    <!--      />-->
    <!--    </vue-html2pdf>-->
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormGroup, BOverlay, BRow, BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'ClassReceiptsCourseSemester',
  directives: {
    Ripple,
  },
  components: {
    BTable,
    BButton,
    BFormGroup,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    vSelect,
    VueHtml2pdf,
  },
  data() {
    return {
      trainingSystemSelected: undefined,
      courseSelected: undefined,
      majorSelected: undefined,
      classSelect: undefined,
      semesterSelected: undefined,
      isLoading: false,
      contentRendered: false,
      generatingPdf: false,
      pdfDownloaded: false,
      controlValue: {
        showLayout: false,
        floatLayout: true,
        enableDownload: true,
        previewModal: true,
        paginateElementsByHeight: 1100,
        manualPagination: false,
        filename: 'Danhsachphach',
        pdfQuality: 2,
        pdfFormat: 'a4',
        pdfOrientation: 'portrait',
        pdfContentWidth: '800px',
      },
      contentPdf: {
        heDaoTao: 'Đại học chính quy',
        lop: null,
        namHoc: null,
        hocKy: null,
        phongThi: null,
        monHoc: null,
        lanThi: 1,
      },
      fieldsTable: [
        { key: 'index', label: '#' },
        { key: 'student_code', label: 'Mã sinh viên' },
        { key: 'last_name', label: 'Họ' },
        { key: 'first_name', label: 'Tên' },
        { key: 'birthday', label: 'Ngày sinh' },
        { key: 'class_name', label: 'Lớp' },
        { key: 'major_name', label: 'Ngành' },
        { key: 'total', label: 'Phải nộp' },
        { key: 'total_exemption', label: 'Miễn giảm' },
        { key: 'amount', label: 'Tổng phải nộp' },
        { key: 'is_receipts', label: 'Trạng thái' },
      ],
      itemsTable: [],
    }
  },
  computed: {
    ...mapGetters({
      dataLists: 'classReceiptsCourseSemester/dataLists',
      allTrainingSystems: 'classReceiptsCourseSemester/allTrainingSystems',
      listTrainingSystemCourses: 'classReceiptsCourseSemester/listTrainingSystemCourses',
      listCourseMajors: 'classReceiptsCourseSemester/listCourseMajors',
      listCourseMajorClasses: 'classReceiptsCourseSemester/listCourseMajorClasses',
      allSemesters: 'classReceiptsCourseSemester/allSemesters',
    }),
    htmlToPdfOptions() {
      return {
        margin: 0,
        filename: 'Danhsachphach.pdf',
        image: {
          type: 'jpeg',
          quality: 0.98,
        },
        enableLinks: true,
        html2canvas: {
          scale: this.controlValue.pdfQuality,
          useCORS: true,
        },
        jsPDF: {
          unit: 'in',
          format: this.controlValue.pdfFormat,
          orientation: this.controlValue.pdfOrientation,
        },
      }
    },

    trainingSystemsOptions() {
      return this.allTrainingSystems.map(item => ({ value: item.id, label: item.name }))
    },
    courseOptions() {
      return this.listTrainingSystemCourses.map(item => ({ value: item.id, label: item.name }))
    },
    majorOptions() {
      return this.listCourseMajors.map(item => ({ value: item.id, label: item.name }))
    },
    classOptions() {
      return this.listCourseMajorClasses.map(item => ({ value: item.id, label: item.name }))
    },
    semesterOptions() {
      return this.allSemesters.map(item => ({ value: item.id, label: item.name }))
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.begin(),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      begin: 'classReceiptsCourseSemester/begin',
      getData: 'classReceiptsCourseSemester/getData',
      getListTrainingSystemCourses: 'classReceiptsCourseSemester/getListTrainingSystemCourses',
      getListCourseMajors: 'classReceiptsCourseSemester/getListCourseMajors',
      getListCourseMajorClasses: 'classReceiptsCourseSemester/getListCourseMajorClasses',
    }),

    onChangeTrainingSystem() {
      if (this.trainingSystemSelected == null) {
        this.reset()
        this.$store.commit('classReceiptsCourseSemester/SET_LISTTRAININGSYSTEMCOURSES', { data: [] })
        this.$store.commit('classReceiptsCourseSemester/SET_LISTCOURSEMAJORS', { data: [] })
        this.$store.commit('classReceiptsCourseSemester/SET_LISTCOURSEMAJORCLASSES', { data: [] })
        this.courseSelected = null
        this.majorSelected = null
        this.classSelect = null
      } else {
        this.getListTrainingSystemCourses({
          training_system_id: this.trainingSystemSelected,
        })
      }
    },
    onChangeCourse() {
      if (this.courseSelected == null) {
        this.reset()
        this.$store.commit('classReceiptsCourseSemester/SET_LISTCOURSEMAJORS', { data: [] })
        this.$store.commit('classReceiptsCourseSemester/SET_LISTCOURSEMAJORCLASSES', { data: [] })
        this.majorSelected = null
        this.classSelect = null
      } else {
        this.getListCourseMajors({
          course_id: this.courseSelected,
        })
      }
    },
    onChangeMajor() {
      if (this.majorSelected == null) {
        this.reset()
        this.$store.commit('classReceiptsCourseSemester/SET_LISTCOURSEMAJORCLASSES', { data: [] })
        this.classSelect = null
      } else {
        this.getListCourseMajorClasses({
          course_id: this.courseSelected,
          major_id: this.majorSelected,
        })
      }
    },
    async reset() {
      await this.$store.commit('classReceiptsCourseSemester/SET_DATA', { data: [] })
      this.itemsTable = this.dataLists
      this.$refs.table.refresh()
    },
    async onSelect() {
      this.isLoading = true
      try {
        await this.getData({
          course_id: this.courseSelected,
          class_id: this.classSelect,
          semester_id: this.semesterSelected,
          day_start: '',
          day_end: '',
          student_id: -1,
        })
        this.itemsTable = this.dataLists
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    exportExcel() {
      this.isLoading = true
      if (this.itemsTable.length > 0) {
        try {
          const tHeader = ['Mã sinh viên', 'Họ đệm', 'Tên', 'Ngày sinh', 'Lớp học', 'Ngành', 'Phải nộp', 'Miễn giảm', 'Tổng phải nộp', 'Trạng thái']
          const filterVal = ['student_code', 'last_name', 'first_name', 'birthday', 'class_name', 'major_name', 'total', 'total_exemption', 'amount', 'is_receipts']
          const dataJson = this.formatJson(filterVal, this.itemsTable)
          import('@/utils/Export2Excel').then(excel => {
            excel.export_json_to_excel({
              header: tHeader,
              data: dataJson,
              filename: 'Báo_cáo_tình_hình_nộp_học_phí_của_sinh_viên_theo_học_kỳ',
              autoWidth: true,
              bookType: 'xlsx',
            })
          })
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Chưa thực hiện lấy danh sách phách',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.isLoading = false
      }
    },

    async downloadPdf() {
      this.isLoading = true
      if (this.avgStudentClasss.length > 0) {
        try {
          if (!(await this.validateControlValue())) return
          this.$refs.html2Pdf.generatePdf()
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Chưa thực hiện lấy danh sách phách',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.isLoading = false
      }
    },
    validateControlValue() {
      if (this.controlValue.pdfQuality > 2) {
        alert('pdf-quality value should only be 0 - 2')
        this.controlValue.pdfQuality = 2

        return false
      }

      if (!this.controlValue.paginateElementsByHeight) {
        alert('paginate-elements-by-height value cannot be empty')
        this.controlValue.paginateElementsByHeight = 1400

        return false
      }

      const paperSizes = [
        'a0',
        'a1',
        'a2',
        'a3',
        'a4',
        'letter',
        'legal',
        'a5',
        'a6',
        'a7',
        'a8',
        'a9',
        'a10',
      ]

      if (!paperSizes.includes(this.controlValue.pdfFormat)) {
        alert(`pdf-format value should only be ${paperSizes}`)
        this.controlValue.pdfFormat = 'a4'

        return false
      }

      if (!this.controlValue.pdfOrientation) {
        alert('pdf-orientation value cannot be empty')
        this.controlValue.pdfOrientation = 'portrait'

        return false
      }

      if (!this.controlValue.pdfContentWidth) {
        alert('pdf-content-width value cannot be empty')
        this.controlValue.pdfContentWidth = '800px'

        return false
      }

      return true
    },

    hasDownloaded(blobPdf) {
      this.pdfDownloaded = true
    },

    domRendered() {
      this.contentRendered = true
    },
  },
  beforeRouteUpdate(to, from, next) {
    // Call the API query method when the URL changes
    this.reset()
    next()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
